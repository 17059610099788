import React from "react";
import Layout from "@/components/layout";
import HeaderOne from "@/components/header-one";
import MenuContextProvider from "@/context/menu-context";
import PageHeader from "@/components/page-header";
import Footer from "@/components/footer";
import BlogDetails1 from "@/components/blog-details1";
import { Helmet as Head } from "react-helmet";

const BlogDetailsPage = () => {
  return (
    <MenuContextProvider>
      <Layout PageTitle="Blog Details Page">
        <Head>
          <title>
            How You Can Prepare for Seasonal Pest Control | Nifixeci
          </title>
          <meta
            name="description"
            content="It is important to understand the seasonality of pests and their
            behavior to ideally tackle infestations."
          />
        </Head>
        <HeaderOne />
        <PageHeader title="Blog Details" name="Blog" />
        <BlogDetails1 />
        <Footer />
      </Layout>
    </MenuContextProvider>
  );
};

export default BlogDetailsPage;
